const initialState = {
    errorMessage: "",
    error: false
};

const getters = {
    isError: state => state.error,
    getErrorMessage: state => state.errorMessage
};

const actions = {

};

const mutations = {
    raiseError(state, payload) {
        state.error = true;
        state.errorMessage = payload;
    },

    dismissError(state) {
        state.error = false;
        state.errorMessage = "";
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
