import auth from '@/api/auth';
import session from '@/api/session';


import {
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_TOKEN,
} from './types';

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
// const isProduction = process.env.NODE_ENV === 'production';

const initialState = {
  authenticating: false,
  error: false,
  token: null,
  username: null,
  verified: true,
  verified_sms_email: true,
  verified_bank: true,
  staff: false,
  moderator: false,
  member_status: null,
  firstName: null,
  lastName: null,
  gender: null,
  user_id: null,
};

const getters = {
  isAuthenticated: state => !!state.token,
  username: state => state.username,
  isVerified: state => state.verified,
  isVerifiedSmsEmail: state => state.verified_sms_email,
  isVerifiedBank: state => state.verified_bank,
  isStaff: state => state.staff,
  moderator: state => state.moderator,
  getMemberStatus: state => state.member_status,
  firstName: state => state.firstName,
  lastName: state => state.lastName,
  gender: state => state.gender,
  user_id: state => state.user_id,
};

const actions = {
  login({ commit }, { username, password }) {
    commit(LOGIN_BEGIN);
    return auth.login(username, password)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => {
        commit(LOGIN_SUCCESS);
        commit('username', username);
      });
  },
  logout({ commit }) {
    return auth.logout()
      .then(() => commit(LOGOUT))
      .finally(() => commit(REMOVE_TOKEN));
  },
  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const username = localStorage.getItem('username');

    // if (isProduction && token) {
    //   commit(REMOVE_TOKEN);
    // }

    if (token) {
      commit(SET_TOKEN, token);
      commit('username', username)
    }
  },
};

const mutations = {
  user_id(state, user_id) {
    state.user_id = user_id;
  },
  verified(state, verified) {
    state.verified = verified;
  },
  verified_sms_email(state, verified) {
    state.verified_sms_email = verified;
  },

  verified_bank(state, verified) {
    state.verified_bank = verified;
  },

  staff(state, staff) {
    state.staff = staff;
  },
  moderator(state, moderator) {
    state.moderator = moderator;
  },

  setMemberStatus(state, member_status) {
    state.member_status = member_status;
  },
  username(state, username) {
    localStorage.setItem('username', username)
    state.username = username;
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
  },
  setLastName(state, lastName) {
    state.lastName = lastName;
  },
  setGender(state, gender) {
    state.gender = gender;
  },
  [LOGIN_BEGIN](state) {
    state.authenticating = false;
    state.error = false;
    state.token = null;
    state.username = null;
    state.verified = true;
    state.verified_sms_email = true;
    state.verified_bank = true;
    state.staff = false;
    state.moderator = false;
    state.member_status = null;
    state.firstName = null;
    state.lastName = null;
    state.gender = null;
    state.user_id = null;

    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_FAILURE](state) {
    state.authenticating = false;
    state.error = true;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
    state.token = null;
    state.username = null;
    state.verified = false;
    state.verified_bank = false;
    state.verified_sms_email = false;
    state.staff = false;
    state.moderator = false;
    state.member_status = null;
    state.firstName = null;
    state.lastName = null;
    state.gender = null;
    state.user_id = null;

    state.token = null;
    state.authenticating = false;
    state.error = false;
  },
  [SET_TOKEN](state, token) {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    session.defaults.headers.Authorization = `Token ${token}`;
    state.token = token;
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete session.defaults.headers.Authorization;
    state.token = null;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
