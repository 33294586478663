import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import DatetimePicker from '../index'

Vue.use(Vuetify);
Vue.use(DatetimePicker)


import sk from 'vuetify/lib/locale/sk';
import cs from 'vuetify/lib/locale/cs';
import en from 'vuetify/lib/locale/en';

export default new Vuetify({
    lang: {
        locales: { sk, cs, en },
        current: 'sk'
    },
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
